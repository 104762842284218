<template>
    <div class="container-fluid content-blocks pt-no-header">
        <div class="not-found-content text-md text-center">
            <template v-if="$i18n.locale == 'fr'">
                <p>Die Seite haben wir leider nicht gefunden.(FR)</p>
                <p>Da wir eine neue Website haben, sind manche Links veraltet. Aber schauen Sie doch auf unserer <router-link :to="{ name: 'JournalOverview', params: {Locale: $route.params.locale || ''} }">Startseite</router-link> vorbei. Vielleicht werden Sie auch über unsere <router-link :to="$tc('routePaths.search')">Suche</router-link> fündig.(FR)</p>
            </template>
            <template v-else-if="$i18n.locale == 'en'">
                <p>Die Seite haben wir leider nicht gefunden.(EN)</p>
                <p>Da wir eine neue Website haben, sind manche Links veraltet. Aber schauen Sie doch auf unserer <router-link :to="{ name: 'JournalOverview', params: {Locale: $route.params.locale || ''} }">Startseite</router-link> vorbei. Vielleicht werden Sie auch über unsere <router-link :to="$tc('routePaths.search')">Suche</router-link> fündig.(EN)</p>
            </template>
            <template v-else>
                <p>Die Seite haben wir leider nicht gefunden.</p>
                <p>Da wir eine neue Website haben, sind manche Links veraltet. Aber schauen Sie doch auf unserer <router-link :to="{ name: 'JournalOverview', params: {Locale: $route.params.locale || ''} }">Startseite</router-link> vorbei. Vielleicht werden Sie auch über unsere <router-link :to="$tc('routePaths.search')">Suche</router-link> fündig.</p>
            </template>
        </div>
    </div>
</template>

<script>
import { i18n } from '@/i18n'
export default {
  name: 'NotFound',
}
</script>
