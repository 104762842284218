<template>
    <div class="entry-header page-header page-header-multimedia-header d-flex flex-column justify-content-center align-items-center">
        <figure v-if="entry && entry.headerMedia" class="entry-header-media h-100 w-100">
            <Vue3Lottie v-if="entry.headerMedia.type == 'lottie'" :animationLink="entry.headerMedia.src" height="100%" width="100%" />
            <video v-else-if="entry.headerMedia.type == 'video'" class="entry-header-video" autoplay muted loop>
                <source :src="entry.headerMedia.src" :type="entry.headerMedia.mime">
            </video>          
        </figure>
        <div v-if="entry && entry.headerText && entry.headerText.length" class="container-fluid entry-header-text text-heading-md position-absolute" :class="entry.theme ? entry.theme.headerTextStyle : null">
            <h1 class="entry-title" v-html="entry.headerText"></h1>
        </div>
    </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie';
export default {
    name: 'HeaderMultimediaHeader',
    components: {
        Vue3Lottie,
    },
    props: {
        entry: null,
    },
    data() {
        return {
            gradientObserver: null,
            gradientObserverTargetEl: null,
        }
    },
    computed: {},
    mounted() {
        this.initColorGradient();
    },
    activated() {
        if (this.gradientObserverTargetEl && this.gradientObserver) {
            console.log('activated');
            
            this.gradientObserver.observe(this.gradientObserverTargetEl);
        }
    },
    unmounted() {
        if (this.gradientObserverTargetEl && this.gradientObserver) {
            this.gradientObserver.unobserve(this.gradientObserverTargetEl);
        }
    },
    deactivated() {
        if (this.gradientObserverTargetEl && this.gradientObserver) {
            this.gradientObserver.unobserve(this.gradientObserverTargetEl);
        }
    },
    methods: {
        initColorGradient() {
            if (this.entry && this.entry.theme.headerColorGradient) {
                var blockElements = document.querySelectorAll(".entry-content .block:not(.bg-themeColor)");
                if (blockElements.length) {
                    this.gradientObserverTargetEl = document.createElement("div");
                    this.gradientObserverTargetEl.classList.add('page-header-color-gradient');
                    blockElements[0].parentNode.insertBefore(this.gradientObserverTargetEl, blockElements[0]);
                    this.gradientObserver = new IntersectionObserver(this.gradientObserverCallback);
                    this.gradientObserver.observe(this.gradientObserverTargetEl);
                }
            }
        },
        gradientObserverCallback(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.boundingClientRect.top > 0) {
                    this.gradientObserverTargetEl.classList.add('scrolling-gradient');    
                } else if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
                    this.gradientObserverTargetEl.classList.remove('scrolling-gradient');    
                }
            });
        }

    },
};
</script>
