<template>
    <footer v-if="globalSiteData" class="site-footer text-sm container-fluid" :class="$route.name === 'JournalEntry' || ($route.name === 'JournalOverview' && 'bg-black')">
        <div class="row footer-row">
            <div class="footer-col footer-text-col col-12 col-md-6 col-lg-3 order-lg-1 border-left-md-only-0" :id="$tc('siteFooter.hashAnchors.contact')">
                <div v-if="globalSiteData.footerBlockThree" v-html="globalSiteData.footerBlockThree" class="footer-block-three"></div>
            </div>
            <div class="footer-col footer-teaser-col col-12 col-md-6 col-lg-6 order-lg-0 border-left-lg-0">
                <div v-if="globalSiteData.footerBlockOne" v-html="globalSiteData.footerBlockOne" class="footer-block-one d-flex flex-column h-100"></div>
            </div>
            <div class="footer-col footer-text-col col-12 col-md-6 col-lg-3 col-lg-3 order-lg-0 border-left-md-only-0" :id="$tc('siteFooter.hashAnchors.sitemap')">
                <template v-if="globalSiteData.footerBlockTwo">
                    <div class="mobile-sitemap-dropdown-header">
                        <button type="button" class="mobile-sitemap-dropdown-toggle-btn btn-plain-text" @click="toggleMobileSitemapDropdown">
                            <span v-if="showMobileSitemapDropdown" v-html="$tc('siteFooter.sitemapToggleBtn.hide')"></span>
                            <span v-else v-html="$tc('siteFooter.sitemapToggleBtn.show')"></span>
                        </button>
                    </div>
                    <div class="footer-block-two mobile-sitemap-dropdown" :class="{'show': showMobileSitemapDropdown}" :style="{ maxHeight: mobileSitemapDropdownMaxH }">
                        <div class="mobile-sitemap-dropdown-inner" v-html="globalSiteData.footerBlockTwo"></div>
                    </div>
                </template>
            </div>
            <div class="footer-animation-col footer-col col-12 col-md-6 col-lg-3 order-lg-1 d-flex align-content-end border-left-lg-0">
                <Vue3Lottie :animationData="animation" :loop="true" :autoPlay="true" height="auto" width="100%" />
            </div>
        </div>
    </footer>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';
import lineFooter from '../assets/animations/line_footer.json';
import InternalLinkReplacementMixin from '@/mixins/InternalLinkReplacementMixin';
export default {
    name: 'SiteFooter',
    components: {
        Vue3Lottie,
    },
    mixins: [InternalLinkReplacementMixin],
    data() {
        return {
            animation: lineFooter,
            showMobileSitemapDropdown: false,
            mobileSitemapDropdownMaxH: null,
            mobileSitemapDropdownT: false,
        };
    },
    computed: {
        globalSiteData() {
            return this.$store.getters['general/get'];
        },
    },
    methods: {
        toggleMobileSitemapDropdown() {
            if (this.mobileSitemapDropdownT) {
                clearTimeout(this.mobileSitemapDropdownT);
            }
            this.mobileSitemapDropdownMaxH = '100vh';
            const timeoutD = this.showMobileSitemapDropdown ? 0 : 600;
            this.showMobileSitemapDropdown = !this.showMobileSitemapDropdown;
            const that = this;
            this.mobileSitemapDropdownT = setTimeout(function () {
                that.mobileSitemapDropdownMaxH = null;
            }, timeoutD);

        },
        footerOnHashChange(e) {
            const hash = location.hash;
            if (hash == '#'+this.$tc('siteFooter.hashAnchors.sitemap')) {
                if (!this.showMobileSitemapDropdown) {
                    this.toggleMobileSitemapDropdown();
                }
            }
        }
    },
    mounted() {
        window.addEventListener('hashchange', this.footerOnHashChange);
    },
    updated() {
        if(this.globalSiteData) {
            this.$nextTick(() => {
                this.initInternalLinks(this.$el);
            });
        }
    },
    unmounted() {
        window.removeEventListener('hashchange', this.footerOnHashChange);
    },
};
</script>
