<template>
    <div class="entry-header page-header page-header-multimedia-header-two-cols d-flex justify-content-center align-items-center flex-wrap">
        <figure v-if="entry && entry.headerMedia" class="entry-header-media h-100 w-50">
            <Vue3Lottie v-if="entry.headerMedia.type == 'lottie'" :animationLink="entry.headerMedia.src" height="100%" width="100%" />
            <video v-else-if="entry.headerMedia.type == 'video'" class="entry-header-video" autoplay muted loop>
                <source :src="entry.headerMedia.src" :type="entry.headerMedia.mime">
            </video>          
        </figure>
        <figure v-if="entry && entry.headerMediaTwo" class="entry-header-media h-100 w-50">
            <Vue3Lottie v-if="entry.headerMediaTwo.type == 'lottie'" :animationLink="entry.headerMediaTwo.src" height="100%" width="100%" />
            <video v-else-if="entry.headerMediaTwo.type == 'video'" class="entry-header-video" autoplay muted loop>
                <source :src="entry.headerMediaTwo.src" :type="entry.headerMediaTwo.mime">
            </video>   
        </figure>
    </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie';
export default {
    name: 'HeaderMultimediaHeaderTwoCols',
    components: {
        Vue3Lottie,
    },
    props: {
        entry: null,
    }
};
</script>
