<template>
    <div class="event-entry-header-wrapper" :class="{ 'has-header-animation': animateContent, 'header-animation-finished': animateContent && animationFinished }">
        <div class="container-fluid entry-header content-blocks content-blocks-centered journal-entry-header event-entry-header d-flex flex-column" :class="{ cover: animateContent && !showAnimatedContent, animated: animateContent }">
            <div class="text-heading-md position-absolute animation-heading">
                <h1 class="entry-title" v-html="entry.title"></h1>
            </div>
        </div>
        <div v-if="(hasHeaderImage || hasHeaderAnimation)" class="journal-entry-header-static-image d-flex justify-content-center w-100">
            <figure class="media-container">

                <Vue3Lottie v-if="hasHeaderAnimation" :animationLink="this.entry.headerMedia.src" height="100%" width="100%" />
            
                <img v-if="hasHeaderImage" :src="headerImageSrc.src" :width="headerImageSrc.width" :height="headerImageSrc.height" :alt="this.entry.headerMedia.title" :srcset="headerImageSrcset ? headerImageSrcset : null"  :sizes="headerImageSrcset ? ['(min-width: 1280px) 50vw', '(min-width: 768px) 75vw', '100vw'] : null" />
                
            </figure>
        </div>
    </div>
</template>

<script>import { Vue3Lottie } from 'vue3-lottie';
export default {
    name: 'EventEntryHeader',
    components: {
        Vue3Lottie, 
    },
    props: {
        entry: {
            default: null,
        },
        animateContent: {
            default: false,
        },
        showContent: {
            default: true,
        },
    },
    data() {
        return {
            animationInterval: false,
            showAnimatedContent: false,
            animationFinished: false,
            defaultImageSize: 'small',
        };
    },
    computed: {
        hasHeaderAnimation() {
            return this.entry && this.entry.headerMedia && this.entry.headerMedia.type == 'lottie';
        },
        hasHeaderImage() {
            return this.entry && this.entry.headerMedia && this.entry.headerMedia.type == 'image';
        },
        headerImageSrc() {
            if (this.hasHeaderImage && Object.keys(this.entry.headerMedia.srcs).length) {
                return this.entry.headerMedia.srcs.length > 1 ? this.entry.headerMedia.srcs[this.defaultImageSize] : this.entry.headerMedia.srcs[Object.keys(this.entry.headerMedia.srcs)[0]];
            }
            return null;
        },
        headerImageSrcset() {
            if (this.hasHeaderImage && Object.keys(this.entry.headerMedia.srcs).length > 1) {
                let srcSetArr = [];
                for (const [key, set] of Object.entries(this.entry.headerMedia.srcs)) {
                    srcSetArr.push(set.src + ' ' + set.width + 'w');
                }
                return srcSetArr;
            }
            return null;
        },
    },
    watch: {
        showContent: {
            handler(newValue) {
                if (newValue && this.animateContent) {
                    this.startTitleAnimation();
                }
            },
        },
    },
    mounted() {
        if (!this.animateContent) {
            this.showAnimatedContent = false;
            this.animationFinished = false;
        } else if (this.showContent) {
            this.startTitleAnimation();
        }
    },
    unmounted() {
        this.showAnimatedContent = false;
        this.animationFinished = false;
    },
    activated() {
        if (!this.animateContent) {
            this.showAnimatedContent = false;
            this.animationFinished = false;
        } else if (this.showContent) {
            this.startTitleAnimation();
        }
    },
    deactivated() {
        this.showAnimatedContent = false;
        this.animationFinished = false;
    },
    methods: {
        startTitleAnimation() {
            var that = this;
            setTimeout(function () {
                //showAnimatedContent needs timeout, otherwise no animation on reactivate
                that.showAnimatedContent = true;
                setTimeout(function () {
                    that.animationFinished = true;
                }, 900);
            }, 30);
        },
    },
};
</script>
