<template>
    <div class="container-fluid entry-header journal-start-animation bg-black d-flex flex-column justify-content-center align-items-center">
        <div class="text-heading-md position-absolute animation-heading">
            <transition name="fade-title">
                <h1 v-show="showTitle" class="entry-title text-white" v-html="titles && titles[activeTitleIndex]"></h1>
            </transition>
        </div>
        <Vue3Lottie class="blend-animation" v-if="renderLottie && showTitle" :animationData="animations[Math.floor(Math.random() * animations.length)]" :autoPlay="true" :delay="animationDelay" height="100%" width="35%" :loop="0" @onComplete="hideJournalStartAnimation" />
    </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie';
import line01 from '../assets/animations/line_01.json';
import line02 from '../assets/animations/line_02.json';
import line03 from '../assets/animations/line_03.json';
import line04 from '../assets/animations/line_04.json';
import line05 from '../assets/animations/line_05.json';
import line06 from '../assets/animations/line_06.json';
import line07 from '../assets/animations/line_07.json';
import line08 from '../assets/animations/line_08.json';
import line09 from '../assets/animations/line_09.json';
import line10 from '../assets/animations/line_10.json';
import line11 from '../assets/animations/line_11.json';
import line12 from '../assets/animations/line_12.json';
import line13 from '../assets/animations/line_13.json';
import line14 from '../assets/animations/line_14.json';
import line15 from '../assets/animations/line_15.json';
import line16 from '../assets/animations/line_16.json';
export default {
    components: {
        Vue3Lottie,
    },
    props: {
        titles: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            animations: [line01, line02, line03, line04, line05, line06, line07, line08, line09, line10, line11, line12, line13, line14, line15, line16],
            active: 0,
            activeTitleIndex: 0,
            showTitle: false,
            renderLottie: true,
            animationDelay: 400,
        };
    },
    mounted() {
        document.documentElement.classList.add('overflow-hidden');
    },
    updated() {
        if(!this.showTitle && this.titles && this.titles.length) {
            this.activeTitleIndex = Math.floor(Math.random() * this.titles.length);
            this.showTitle = true;
        }
    },
    activated() {
        if(!this.showTitle && this.titles && this.titles.length) {
            this.activeTitleIndex = Math.floor(Math.random() * this.titles.length);
            this.showTitle = true;
        }
    },
    methods: {
        hideJournalStartAnimation() {
            this.$store.commit('view/setJournalStartAnimationDisplayOption', false);
            document.documentElement.classList.remove('overflow-hidden');
        }
    },
};
</script>
<style scoped>
.blend-animation {
    mix-blend-mode: exclusion;
}
.fade-title-enter-active,
.fade-title-leave-active {
    transition: transform 400ms ease;
}
.fade-title-enter-from,
.fade-title-leave-to {
    transform:scale(0);
}
</style>
